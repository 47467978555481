import {TweenLite} from 'gsap';

export default class FAQBlock {
  static getTemplate(data) {
    if (data.category == null) return;
    let template = `
      <div class="faq-head">
        <div class="faq-head__category">
            <div class="category">
              <div class="category__icon">
                <img src="${data.category.img_ajax}">
              </div>
              <span class="category__text">${data.category.name}</span>
            </div>
        </div>
        <a class="faq-head__title" href="${data.url}" target="_blank">
          <span itemprop="name">${data.title}</span>
        </a>`;

    if (data.head_text !== null) {
      template += `<div class="faq-head__text">${data.head_text}</div>`;
    }

    template += `</div>`;
    if (data.content) {
      template += `
        <noindex>
          <div class="faq-body" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
            <div itemprop="text">${data.content_preview}</div>
          </div>
        </noindex>
        <a class="faq-toggle" href="${data.url}" target="_blank">
          <div class="faq-toggle__text">Читать полностью</div>
          <div class="faq-toggle__icon">
            <svg width="12" height="7" viewBox="0 0 16 9" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.20792 8.67619L.32839 1.88741c-.43785-.43185-.43785-1.13202 0-1.56365.43745-.43168 1.14697-.43168 1.58439 0l6.08733 6.00702L14.08724.32393c.43763-.43167 1.14708-.43167 1.58453 0 .43764.43167.43764 1.13181 0 1.56366L8.79213 8.67637C8.5733 8.8922 8.2868 9 8.00015 9c-.28679 0-.5735-.10801-.79223-.32381z" fill="#B5BBC2" fill-rule="nonzero"></path>
            </svg>
          </div>
        </a>
      `;
    }

    return template;
  }

  constructor(element) {
    this.element = element;
    this.head = element.querySelector('.faq-head');
    this.body = element.querySelector('.faq-body');
    this.toggle = element.querySelector('.faq-toggle');

    if (!this.toggle.classList.contains('faq-toggle-on')) {
      return;
    }

    let size;

    if (window.innerWidth <= 768 && window.innerWidth > 500) {
      size = 306;
    } else if (window.innerWidth <= 500) {
      size = 260;
    } else {
      size = 460;
    }

    if (this.body.textContent.length < size) {
      this.toggle.style.display = 'none';
      this.body.style.height = 'auto';
      this.body.style.minHeight = '0';
      this.element.classList.remove('faq-block_cursor-pointer');
    } else {
      if (this.toggle) {
        this.toggleText = this.toggle.querySelector('.faq-toggle__text');
        this.body.addEventListener('click', (event) => {
          if (!this.isOpen) {
            this.toggleBody();
          }
        });

        this.toggle.addEventListener('click', (event) => {
          event.preventDefault();
          this.toggleBody();
        });

        !this.element.classList.contains('faq-block_cursor-pointer')
          ? this.element.classList.add('faq-block_cursor-pointer')
          : null;
      }
    }
  }

  toggleBody() {
    if (this.isOpen) {
      TweenLite.to(this.body, 0.3, {
        height: 0,
        onComplete: () => {
          this.element.classList.remove('faq-block_open');
          this.head.classList.remove('faq-block_open');
          this.toggleText.innerHTML = 'Читать полностью';
        },
      });
    } else {
      TweenLite.fromTo(this.body, 0.3, {
            height: 0
          },
          {
            height: this.body.scrollHeight,
            onComplete: () => {
              this.element.classList.add('faq-block_open');
              this.head.classList.add('faq-block_open');
              this.toggleText.innerHTML = 'Свернуть';
            }
          }
      );
    }
  }

  get isOpen() {
    return this.element.classList.contains('faq-block_open');
  }
}
