export default class SearchBar {
  static init() {
    const searchWrapper = document.querySelector('.content-search');
    return searchWrapper ? new SearchBar() : null;
  }

  constructor() {
    this.wrapper = document.querySelector('.content-search');
    this.input = this.wrapper.querySelector('.content-search__input');
    this.hintWrapper = this.wrapper.querySelector('.content-search__hint-wrapper');
    this.clearButton = this.wrapper.querySelector('.content-search__clear-button');
    // debugger
    this.bindEvents();
  }

  showClearButton() {
    this.clearButton.style.display = 'block';
  }

  hideClearButton() {
    this.clearButton.style.display = 'none';
  }

  clearInput() {
    this.input.value = '';
    this.input.focus();
    this.hintWrapper.innerHTML = '';
    this.hintWrapper.classList.remove('content-search__hint-wrapper_active');
  }

  bindEvents() {
    if (!this.clearButton) {
      return;
    }

    this.clearButton.addEventListener('click', (event) => {
      event.preventDefault();
      this.clearInput();
      this.hideClearButton();
    });

    const inputEvents = ['input', 'paste', 'cut'];

    inputEvents.forEach(eventName =>
      this.input.addEventListener(eventName, () => {
        this.input.value.length
          ? this.showClearButton()
          : this.hideClearButton();

        if (this.input.value.length) {
          const headers = new Headers({'X-Requested-With': 'XMLHttpRequest'});
          let url;

          url = `/api/search/?q=${this.input.value}`;

          return fetch(url, {headers})
            .then(response => response.json())
            .then((data) => {
              if (data.length) {
                this.hintWrapper.innerHTML = '';
                data.forEach((element) => {
                  const hint = document.createElement('a');
                  hint.classList.add('content-search__hint');
                  hint.setAttribute('href', element.url);
                  hint.innerHTML = `
                    <span class="content-search__hint-title">${element.title}</span>
                    <span class="content-search__hint-text">${element.text}</span>
                  `;

                  this.hintWrapper.appendChild(hint);
                });
                this.hintWrapper.classList.add('content-search__hint-wrapper_active');
              }
            });
        } else {
          this.hintWrapper.innerHTML = '';
          this.hintWrapper.classList.remove('content-search__hint-wrapper_active');
        }
      })
    );
  }
}
