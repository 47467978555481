import Maskifier from './maskifier';
import Validator from './validator';
import InputContainer from '../input-container/input-container';
import { load } from 'recaptcha-v3';

export const addHandlerSuccess = (form, callback) => {
  form.onsuccess = form.onsuccess || []
  form.onsuccess.push(callback)
}

/**
 * Abstract Class BaseForm.
 *
 * @class BaseForm
 */
export class BaseForm
{
  constructor(options) {
    this.forms = document.querySelectorAll(`.${options.baseClass}`)
    this.classes = {
      form: options.baseClass,
      input: options.inputClass,
      InputContainer: `${options.baseClass}__input-wrapper`,
    };
    this.validators = []
    this.maskifiers = []
    this.errorWrapper = document.querySelector('.form__error__wrap')

    this.forms.forEach((form) => {

      form.addEventListener('keydown', function(e) {
        if (e.keyCode === 13) {
          e.preventDefault()
        }
      })

      this.validators.push(new Validator({
        form: form,
        patterns: {
          code: /[0-9]/,
          name: /^[a-zA-Z]+|[а-яА-Я]+$/,
          firstName: /[А-Яа-яЁё ]+/,
          email: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/,
          question: /.+/,
        },
      }))

      this.maskifiers.push(
        new Maskifier({
          inputs: form.querySelectorAll(`.${this.classes.input}`),
          masks: {
            date: [/([0 - 2]\d | 3[01])/, '.', /(0\d | 1[012])/, '.', /(\d{ 4 })/],
          },
        })
      );

      const dynamicSizedInputs = form.querySelectorAll(`.${this.classes.input}_dynamic`)

      dynamicSizedInputs.forEach(input => {
        input.addEventListener('input', () => {
          input.style.height = '5px'
          input.style.height = `${input.scrollHeight}px`
        });
      });
    });
  }

  applyHandlersSuccess(form) {
    form.onsuccess && form.onsuccess.forEach(handler => handler())
  }

  async getRecaptchaToken() {
    const key = window.recaptchaKey
    const recaptcha = await load(key)
    return await recaptcha.execute('submit')
  }

  async getDefaultFormData(form) {
    let phoneCode = document.querySelector('.iti__selected-dial-code').innerHTML,
      phone = document.querySelector('[name="phone"]').value,
      email = document.querySelector('[name="email"]').value,
      demo = document.querySelector('[name="demo"]'),
      formData = window.serialize(form)

    let tempArr = formData.split('phone=')
    if (tempArr[1]) {
      tempArr[1] = `${phoneCode} ${tempArr[1]}`
      formData = tempArr.join('phone=')
    } else {
      formData += `&phone=${phoneCode} ${phone}`
    }
    formData += `&email=${email}`
	
	if( demo )
		formData += `&demo=${demo.value}`
    
    await this.getRecaptchaToken().then((token) => {
      formData += `&recaptchaResponse=${token}`
    })

    return formData
  }

  async request(form, event) {
    form.querySelectorAll('button').forEach(button => {
      button.disabled = true
    })
    const formData = await this.getDefaultFormData(form)

    const url = form.getAttribute('action')

    return fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: formData,
    })
      .then(response => {
        form.querySelectorAll('button').forEach(button => {
          button.disabled = false
        })
        if (response.status >= 200 && response.status < 300) {
          return response.json()
        } else {
          const error = new Error(response.statusText)

          error.response = response
          throw error
        }
      })
      .then((data) => {
        data.success ? this.successAction(form) : this.errorAction(form, data.message)
      })
  }

  errorAction(form, message) {
    throw new Error("Method 'errorAction()' must be implemented.");
  }

  successAction(form) {
    throw new Error("Method 'successAction()' must be implemented.");
  }

  submit(form, event) {
    this.validationForm(form, event)
  }

  validationForm(form, event) {
    if (!this.validating) {
      this.validating = true
      if (this.validators.length) {
        this.validators.forEach((validator) => {
          if (validator.form === form) {

            validator.validateAll().then(() => {
              if (form.recaptchaResponse) {
                this.getRecaptchaToken().then(token => {
                  form.recaptchaResponse.value = token
                  this.request(form, event)
                })
              } else {
                this.request(form, event)
              }
            }).catch(() => {
              return false
            })

            setTimeout(() => {
              this.validating = false
            }, 1500)
          }
        })
      }
    }
  }

  init() {
    let inputs = document.querySelectorAll('.form__input')

    if (inputs.length) {
      [...inputs].forEach(input => {
        const container = new InputContainer(input)
        container.init()
      });
    }

    if (this.maskifiers.length) {
      this.maskifiers.forEach((maskifier) => {
        maskifier.init()
      });
    }

    if (this.validators.length) {
      this.validators.forEach((validator) => {
        validator.init()
      });
    }

    const forms = document.querySelectorAll(`.${this.classes.form}`)

    forms.forEach(form => {
      form.addEventListener("submit", event => {
        event.preventDefault();
      })

      form.querySelectorAll("button").forEach(button => {
        button.addEventListener("click", event => {
          event.preventDefault();
          this.submit(form, event)
        })
      })
    })
  }
}

if (document.querySelector('[data-component="news"]')) {
  const mask = new Maskifier({
    inputs: document.querySelectorAll(`.datepicker-modal__input`),
    masks: {
      phone: ['+', '7', ' ', '(', 9, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', '-', ' ', /\d/, /\d/, ' ', '-', ' ', /\d/, /\d/],
      date: [/[0-3]/, /[0-9]/, '.', /[0-1]/, /[0-9]/, '.', /[1-2]/, /\d/, /\d/, /\d/],
    },
  })

  mask.init()
}
