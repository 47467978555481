import * as Swiper from 'swiper/dist/js/swiper.js';

document.addEventListener('DOMContentLoaded', () => {
  [...document.querySelectorAll('.section-metatrader5-main__advantages-mobile')].forEach(metatrader5_slider => {

    const slider = metatrader5_slider.querySelector('.section-metatrader5-main-slider');

    if (!slider) {
      return;
    }

    if (window.innerWidth < 991) {
      const metatrader5Slider = new Swiper(slider, {
        loop: true,
        init: true,
        slidesPerView: 1,
        speed: 500,
        spaceBetween: 16,
        autoHeight: true,
        pagination: {
          el: '.section-metatrader5-swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        breakpoints: {
          // when window width is >= 320px
          340: {
            slidesPerView: 2,
            spaceBetween: 8,

          }
        }
      });
    }

  });
});
