import * as Swiper from 'swiper/dist/js/swiper.js';

[...document.querySelectorAll('.advant_RI')].forEach(advant_RI => {

  const slider = advant_RI.querySelector('.feature-wrapper-slider');

  if (!slider) {
    return;
  }

  if (window.innerWidth < 768) {
    const featureSlider = new Swiper(slider, {
      loop: true,
      init: true,
      slidesPerView: 1,
      speed: 500,
      pagination: {
        el: '.feature-slider-swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    });
  }

});

