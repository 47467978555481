import * as Swiper from 'swiper/dist/js/swiper.js';

document.addEventListener('DOMContentLoaded', () => {
    [...document.querySelectorAll('.analytics_FM__elements-mobile')].forEach(analytics_slider => {

        const slider = analytics_slider.querySelector('.analytics_FM__elements-mobile-slider');
        const bullets = analytics_slider.querySelector('.analytics_FM__elements-mobile-pagination');

        if (!slider) {
            return;
        }

        if (window.innerWidth < 991) {
            const analyticsSlider = new Swiper(slider, {
                init: true,
                slidesPerView: 'auto',
                speed: 500,
                spaceBetween: 0,
                autoHeight: true,
                pagination: {
                    el: bullets,
                    type: 'bullets',
                    clickable: true,
                },
            });
        }

    });
});
