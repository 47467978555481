import { BaseForm } from './baseForm';
import { TimelineLite, TweenLite } from 'gsap';
import SimpleScrollbar from '../../static/js/plugins/simpleScrollbar';

/**
 * @class FeedbackForm
 * @extends {BaseForm}
 */
class FeedbackForm extends BaseForm
{
  errorAction(form, message) {
    this.animateButton(form, message)
  }

  successAction(form) {
    this.applyHandlersSuccess(form)
    this.animateButton(form)

    form.querySelectorAll(`.${this.classes.input}`).forEach((input) => {
      input.value = ''
      input.closest('.input-container_has_value').classList.remove('input-container_has_value')
    })
  }

  animateButton(form, error = null) {
    const button = form.querySelector('.form__button')
    const icon = button.querySelector('.button__icon') || false
    const iconError = button.querySelector('.button__icon__error') || false
    const text = button.querySelector('.button__text')
    const progressbar = form.querySelector('.progressbar')
    const progressbarOuter = progressbar.querySelector('.progressbar__line_outer')
    const errorWrapper = document.querySelector('.form__error__wrap')
    const animation = new TimelineLite()

    animation
      .set(errorWrapper, {
        opacity: 0
      })
      .to(button, 0.3, {
        opacity: 0,
        onComplete() {
          text.textContent = error ? 'Ошибка' : 'Отправлено';
        },
      })
      .set(button, {
        display: 'none',
      })
      .set(progressbar, {
        display: 'block',
      })
      .fromTo(progressbar, 0.3, {
        opacity: 0.1,
      }, {
        opacity: 1,
      })
      .set([error ? iconError : icon], {
        display: 'flex',
        className: '-=hidden',
      })
      .to(progressbarOuter, 0.6, {
        width: '100%',
      })
      .to(progressbar, 0.3, {
        delay: 0.2,
        opacity: 0,
        onComplete() {
          TweenLite.set(progressbarOuter, {
            width: '0',
          });
          TweenLite.set(progressbar, {
            clearProps: 'display',
          });
        },
      })
      .to(button, 0.3, {
        onStart() {
          TweenLite.set(button, {
            clearProps: 'display',
          });
        },
        opacity: 1,
      })
      .set(errorWrapper, {
        onStart() {
          if (error) {
            errorWrapper.innerHTML = `<span>${error}</span>`
            TweenLite.to(errorWrapper, 0.6, {
              opacity: '1',
            })
          }
        }
      })
      .to([error ? iconError : icon, text], 0.3, {
        delay: 1,
        opacity: 0,
        onComplete() {
          text.textContent = 'Отправить';
        },
      })
      .set([error ? iconError : icon], {
        clearProps: 'all',
        className: '+=hidden',
      })
      .to(text, 0.3, {
        opacity: 1,
      })
      .to(errorWrapper, 3, {
        onComplete() {
          if (error) {
            TweenLite.to(errorWrapper, 0.6, {
              opacity: '0',
            })
          }
        }
      })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  let forms = document.querySelectorAll('.form__feedback')

  if (forms) {
    (new FeedbackForm({baseClass: 'form__feedback', inputClass: 'form__input'})).init()

    const setUtmY = setInterval(() => {
      const uid = getCookie('_ym_uid');
      if (uid) {
        clearInterval(setUtmY);

        const utm_y = `utm_y=${uid}`;
        const inputs = document.querySelectorAll('input[name="utm"]');
        if (inputs.length) {
          inputs.forEach(input => {
            if (input.value.includes('utm_y=&')) {
              input.value = input.value.replace('utm_y=&', `${utm_y}&`)
            }
          })
        }
      }
    }, 300)
    function getCookie(name) {
      let cookie = document.cookie.split('; ').find(row => row.startsWith(name + '='));
      return cookie ? cookie.split('=')[1] : null;
    }
  }

  let formSelects = document.querySelectorAll('[data-component="select"]');
  if (formSelects) {
    formSelects.forEach(select => {
      const dropdown = select.querySelector('.select__dropdown');
      const scrollbarWrapper = select.querySelector('.select__dropdown-wrapper');
      const selectHead = select.querySelector('.select__head');
      const list = select.querySelector('.select__list');
      const scrollbar = new SimpleScrollbar(scrollbarWrapper);
      const selectValue = select.querySelector('.select__value');
      const selectHeadText = select.querySelector('.select__head-text');

      document.addEventListener('click', event => {
        if (event.target.closest('.select__dropdown') || event.target.closest('.select__head')) {
          return;
        }

        selectHead.classList.remove('select__head_active');
        dropdown.classList.remove('select__dropdown_show');
      });

      selectHead.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopImmediatePropagation();
        const _self = event.currentTarget;

        if (_self.classList.contains('select__head_active')) {
          _self.classList.remove('select__head_active');
          dropdown.classList.remove('select__dropdown_show');
        } else {
          _self.classList.add('select__head_active');
          dropdown.classList.add('select__dropdown_show');
        }

        scrollbar.update();
      });

      const options = list.querySelectorAll('.select__item');

      options.forEach((item, index, arr) => {
        item.addEventListener('click', e => {
          const active = list.querySelector('.select__item_active');
          if (active)
            active.classList.remove('select__item_active');
          item.classList.add('select__item_active');

          selectHeadText.innerHTML = item.innerHTML;
          selectValue.value = item.dataset.value;

          selectHead.classList.remove('select__head_active');
          dropdown.classList.remove('select__dropdown_show');
        });
      });
    });
  }
})
