import * as Swiper from 'swiper/dist/js/swiper.js';
import Tabs from '../tabs/tabs';

document.addEventListener('DOMContentLoaded', () => {
  const mainSlider = new Swiper('.main-slider', {
    init: false,
    speed: 600,
    spaceBetween: 0,
    observer: true,
    observeParents: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    navigation: {
      nextEl: '.main-slider__control_next',
      prevEl: '.main-slider__control_prev',
    },
    pagination: {
      el: '.main-slider__bullets',
      type: 'bullets',
      bulletClass: 'main-slider__bullet',
      bulletActiveClass: 'main-slider__bullet_is_active',
      clickable: true,
    },
    fadeEffect: {
      crossFade: true,
    },
    effect: 'fade',
    loop: true,
    threshold: 30,
    parallax: true,
  });

  if (document.querySelector('.main-slider')) {
    mainSlider.init();
    let isForeverStop = false;

    const slides = [...document.querySelectorAll('.main-slider__slide')];
    const bullets = [...document.querySelectorAll('.main-slider__bullet')];

    slides.forEach((slide) => {
      slide.addEventListener('mouseenter', () => {
        mainSlider.autoplay.stop();
      });

      slide.addEventListener('mouseleave', () => {
        if (!isForeverStop) {
          mainSlider.autoplay.start();
        }
      });
    });

    bullets.forEach((bullet) => {
      bullet.addEventListener('click', () => {
        mainSlider.autoplay.stop();
        isForeverStop = true;
      });
    });

    mainSlider.on('sliderMove', (event) => {
      mainSlider.autoplay.stop();
      isForeverStop = true;
    });
  }
})
