import Tooltip from 'tooltip.js'

export default class Validator {
  constructor(options) {
    this.form = options.form
    this.inputs = this.form.querySelectorAll('input, textarea')
    this.patterns = options.patterns
  }

  setInvalid(input) {
    input.closest('.input-container').classList.add('invalid-input')
    this.form.classList.add('invalid')
  }

  setValid(input) {
    input.closest('.input-container').classList.remove('invalid-input')
    this.form.classList.remove('invalid')
  }

  checkValidity(input) {
    const regex = this.patterns[input.getAttribute('data-type')]

    if (input.getAttribute('data-type') === 'phone') {
      const initPhoneMask = input.classList.contains('init');
      if (!initPhoneMask || (initPhoneMask && input.value.includes('_'))) {
        this.forcedInvalidatedInput(input)
      } else {
        input.invalid = false
        return true
      }
    } else if (input.getAttribute('data-type') === 'code') {
      if (!regex.test(input.value) || input.value.length !== 4) {
        input.tooltip.updateTitleContent('<span class="tooltip-error">Код должен содержать 4 цифры</span>')
        this.forcedInvalidatedInput(input)
      } else {
        input.invalid = false
        return true
      }
    } else if (!regex.test(input.value)) {
      this.forcedInvalidatedInput(input)
    } else {
      input.invalid = false
      return true
    }
    return false
  }

  forcedInvalidatedInput(input, time = 1500) {
    input.invalid = true
    this.setInvalid(input)
    input.tooltip.show()
    this.timeout = setTimeout(() => {
      this.setValid(input);
      Validator.hideTooltip(input)
    }, time)
  }

  validateAll() {
    let valid = true;

    [...this.inputs].forEach(input => {
      let needValidate = input.isRequired || input.invalid

      if (needValidate && !this.checkValidity(input)) {
        valid = false
      }
    })

    return new Promise((resolve, reject) => {
      if (!valid) {
        return reject(valid)
      }

      return resolve(valid)
    })
  }

  static hideTooltip(input) {
    if (input.tooltip._tooltipNode) {
      input.tooltip._tooltipNode.classList.add('fadeOut')
      setTimeout(() => {
        input.tooltip.hide()
        input.tooltip._tooltipNode.classList.remove('fadeOut')
      }, 300)
    }
  }

  static createTooltip(input) {
    input.tooltip = new Tooltip(input, {
      title: `<span class="tooltip-error">${input.getAttribute('data-invalid-message')}</span>`,
      html: true,
      trigger: 'manual',
      placement: 'bottom',
    })
  }

  init() {
    [...this.inputs].forEach(input => {
      if (input.getAttribute('data-required')) {
        input.isRequired = true
      }
      if (input.dataset.validation !== 'false') {
        Validator.createTooltip(input)
        input.addEventListener('blur', () => {
          if (input.value.length) {
            this.checkValidity(input)
          } else {
            input.invalid = false
          }
        })
        input.addEventListener('focus', () => {
          Validator.hideTooltip(input)
          input.closest('.input-container').classList.remove('valid')
        })
      }
    })
  }
}
