import {TweenLite} from 'gsap';
import 'gsap/CSSPlugin';

export const showPopup = (id) => {
  const popup = document.querySelector(`[data-modal='${id}']`);
  TweenLite.to(popup, 0.3, {
    onStart() {
      TweenLite.set(popup, {
        display: 'flex',
      });

      if (id !== 'eco-filter' && window.innerWidth < 768) {
        TweenLite.set(document.body, {
          overflow: 'hidden',
        });
      }
    },
    opacity: 1,
  });

  setTimeout(() => {
    if (id === 'eco-filter' && window.innerWidth < 768) {
      const button = document.querySelector(`[data-modal-target=${id}]`);
      button.classList.add('filter-advanced-button_open');
    }
  }, 4);

};

export const hidePopup = (popup) => {
  TweenLite.to(popup, 0.3, {
    opacity: 0,
    onComplete() {
      TweenLite.set(popup, {
        clearProps: 'display',
      });
      TweenLite.set(document.body, {
        clearProps: 'overflow',
      });
    },
  });

  if (popup.getAttribute('data-modal') === 'callback') {
    popup.querySelector('.callback-popup__buttons').style.display = 'flex';
    popup.querySelector('.callback-popup__form').style.display = 'none';
  }

  setTimeout(() => {
    if (popup.getAttribute('data-modal') === 'eco-filter' && window.innerWidth < 768) {
      const button = document.querySelector(`[data-modal-target="eco-filter"]`);
      button.classList.remove('filter-advanced-button_open');
    }
  }, 4);
};
document.addEventListener('DOMContentLoaded', () => {
  [...document.querySelectorAll('[data-modal-target]')].forEach((button) => {
    button.addEventListener('click', (event) => {
      event.preventDefault();
      if (button.classList.contains('filter-advanced-button_open')) {
        return;
      }
      const target = button.getAttribute('data-modal-target');
      showPopup(target);
    });
  });

  [...document.querySelectorAll('.popup__close-button')].forEach((button) => {
    button.addEventListener('click', (event) => {
      event.preventDefault();
      const parent = button.closest('.popup');
      hidePopup(parent);
    });
  });
})
window.onload = () =>  {
  window.addEventListener('closePopup', () => {
    [...document.querySelectorAll('.popup')].forEach((popup) => {
      hidePopup(popup);
    });
  });
};
