import maskInput from 'vanilla-text-mask';
import intlTelInput from 'intl-tel-input';

export default class Maskifier {
  constructor(options) {
    this.inputs = options.inputs
    this.masks = options.masks
    this.phoneMasks = [];
  }

  init() {
    [...this.inputs].forEach(input => {
      if (input.getAttribute('data-mask')) {
        let maskType = input.getAttribute('data-mask');

        if (this.masks[maskType]) {
          maskInput({
            inputElement: input,
            mask: this.masks[maskType],
            showMask: true,
          })
        }
        input.value = ''

        if (maskType === 'phone') {
          let countries = input.getAttribute('data-countries');

          input.addEventListener('click', function (e) {

            if (!(/\d/).test(e.target.value)) {
              e.target.setSelectionRange(0,0);
              e.target.focus();
            }
          })

          let iti = intlTelInput(input, {
            separateDialCode: true,
            nationalMode: true,
            autoPlaceholder: 'aggressive',
            initialCountry: 'ru',
            preferenceCountries: ['ru'],
            onlyCountries: JSON.parse(countries),
            utilsScript: '/local/templates/main/utils.js'
          });

          iti.promise.then(() => {
            input.autocomplete = 'on';
            this.createMaskPhone(input);
          })

          input.addEventListener('countrychange', event => {
            event.target.value = '';
            this.createMaskPhone(input);
            let countryCodeInput = input.closest('form').querySelector('[name="countryCode"]'),
                countryCodePopupInput = document.querySelector('.callback-popup__body [name="countryCode"]'),
                countryCode = iti.highlightedItem.dataset.countryCode;
            if (countryCodePopupInput) {
              countryCodePopupInput.value = countryCode;
            }
            countryCodeInput.value = countryCode;
          })

          input.addEventListener('input', event => {
            let countryCode = iti.selectedCountryData.iso2;
            if (countryCode === 'ru') {
              if (
                (event.target.value.substring(0, 1) === '8') ||
                (event.target.value.substring(0, 1) === '7')
              ) {
                event.preventDefault();
                event.target.value = event.target.value.substring(1);
                this.createMaskPhone(input);
                event.target.setSelectionRange(0,0);
                event.target.focus();
              }
            }
          })

          input.addEventListener('paste', event => {
            let paste = event.clipboardData.getData('text');
            if (!paste) {
              paste = window.clipboardData.getData('text');
            }

            if (paste) {
              event.preventDefault();
              paste = paste.replace(/[^0-9]/g, '');
              let placeholder = input.getAttribute('placeholder');
              placeholder = placeholder.replace(/[^0-9]/g, '');

              const countryCode = iti.selectedCountryData.iso2;

              if (countryCode === 'ru' && (paste.substring(0, 1) === '8' || paste.substring(0, 1) === '7')) {
                paste = paste.substring(1);
              }

              if (placeholder.length < paste.length) {
                let dialCode = iti.selectedCountryData.dialCode;
                if (dialCode === paste.substring(0, dialCode.length)) {
                  paste = paste.substring(dialCode.length);
                }
              }

              event.target.value = paste.substring(0, placeholder.length);
            }

            this.createMaskPhone(input);
          })
        }

        if (maskType === 'cyrillic') {
          input.addEventListener('input', event => {
            let res = /[^а-яА-ЯёЁ ]/g.exec(input.value);
            input.value = input.value.replace(res, '');
          })
        }
      }
    })
  }

  createMaskPhone(input) {
    const inputId = input.id;
    if (this.phoneMasks[inputId]) {
      this.phoneMasks[inputId].destroy();
    }

    let arrString = input.getAttribute('placeholder').split('')
    let arrMask = []
    arrString.forEach(function (e) {
      arrMask.push((e === ' ' || e === '-') ? e : /\d/)
    })
    
    this.phoneMasks[inputId] = maskInput({
      inputElement: input,
      mask: arrMask,
      showMask: true,
      autocomplete: true
    })

    if (input.getAttribute('disabled')) {
      input.removeAttribute('disabled');
      input.closest('.phone__input-wrapper').classList.add('init');
      input.classList.add('init');
    }
  }
}
