import 'components/main/main';
import 'components/main-features/main-features';
import 'components/form/feedbackForm';
import 'components/popup/popup';
import 'components/block-metatrader5/block-metatrader5';
import "components/analytics_FM/analytics_FM";
import "components/news/news";
import 'components/download-app-topper-mobile/download-app-topper-mobile';
import "components/advantages-right-image/advantages-right-image";

import FAQBlock from 'components/faq/faq-block';
import SearchBar from 'components/search/search';

const init = component =>
  ('init' in component)
    ? component.init()
    : (typeof component === 'function')
      ? component()
      : null;

document.addEventListener('DOMContentLoaded', () => {
  [
    SearchBar,
  ].map(init);

  const faqElementEnable = () => {
    const faqElements = [...document.querySelectorAll("[data-faq-block]")];
    if (faqElements) {
      faqElements.forEach(element => {
        new FAQBlock(element);
      });
    }
  }
  faqElementEnable();
});
